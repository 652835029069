import { Button, Grid, InputAdornment } from "@mui/material";
import React, { FC } from "react";
import { Formik, Form, Field, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { Phone } from "@mui/icons-material";
import { IconButton } from "gatsby-theme-material-ui";
import * as Yup from "yup";
import { translations } from "../../translations";
import { useSelectedPropertyContext } from "../SelectedPropertyProvider";

export const getClientYupSchema = (selected_locale) => ({
  first_name: Yup.string().required(
    translations[selected_locale].required_field
  ),
  last_name: Yup.string().required(
    translations[selected_locale].required_field
  ),
  email: Yup.string()
    .nullable()
    .email(translations[selected_locale].invalid_email),
  phone_number: Yup.string().required(
    translations[selected_locale].required_field
  ),
});

export const ClientFormFields = () => {
  const { selected_locale } = useSelectedPropertyContext();
  const { values } = useFormikContext();
  return (
    <>
      <Grid item xs={12}>
        <Field
          component={TextField}
          type="text"
          name={`client.first_name`}
          label={translations[selected_locale].given_name}
          fullWidth
          margin="none"
          required
          InputLabelProps={{
            shrink: values.client?.first_name,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          type="text"
          name="client.last_name"
          label={translations[selected_locale].family_name}
          fullWidth
          margin="none"
          required
          InputLabelProps={{
            shrink: values.client?.last_name,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          type="tel"
          name="client.phone_number"
          label={translations[selected_locale].phone_number}
          fullWidth
          margin="none"
          required
          InputProps={{
            endAdornment: values.client?.phone_number && (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  to={`tel:0${values.client.phone_number}`}
                >
                  <Phone />
                </IconButton>
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: values.client?.phone_number,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          component={TextField}
          type="email"
          name="client.email"
          label={translations[selected_locale].email}
          fullWidth
          margin="none"
          InputLabelProps={{
            shrink: values.client?.email,
          }}
        />
      </Grid>
    </>
  );
};

export const ClientForm: FC = ({ initialValues, onSubmit, onDelete }) => {
  const { selected_locale } = useSelectedPropertyContext();
  return (
    <Formik
      initialValues={{ client: initialValues }}
      onSubmit={async (values, { setSubmitting }) => {
        await onSubmit?.(values);
        setSubmitting(false);
      }}
      validationSchema={Yup.object().shape({
        client: Yup.object().shape(getClientYupSchema(selected_locale)),
      })}
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty, setSubmitting }) => {
        return (
          <Form>
            <Grid container spacing={2} mb={2}>
              <ClientFormFields />
            </Grid>
            <Button
              type="submit"
              variant="contained"
              disabled={!dirty || !isValid || isSubmitting}
              sx={{ mr: 1 }}
            >
              {translations[selected_locale].submit}
            </Button>
            {onDelete && (
              <Button
                variant="contained"
                color="error"
                onClick={async () => {
                  setSubmitting(true);
                  await onDelete();
                  setSubmitting(false);
                }}
              >
                {translations[selected_locale].delete}
              </Button>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
