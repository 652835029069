import { Paper } from "@mui/material";
import { Container } from "@mui/system";
import { navigate } from "gatsby";
import { useSnackbar } from "notistack";
import React from "react";
import { ClientForm } from "../../components/forms/Clientform";
import { useSelectedPropertyContext } from "../../components/SelectedPropertyProvider";
import { translations } from "../../translations";
import {
  useDeleteClientByIdMutation,
  useGetClientByIdSubscription,
  useUpdateClientByIdMutation,
} from "../../__generated__/types";

const ClientIdPage = ({ params }) => {
  const { selected_locale } = useSelectedPropertyContext();
  const { data, loading, error } = useGetClientByIdSubscription({
    variables: { id: params.id },
  });
  const [updateClient] = useUpdateClientByIdMutation();
  const [deleteClient] = useDeleteClientByIdMutation();
  const { enqueueSnackbar } = useSnackbar();
  if (loading) return null;
  if (error) return <div>{error.message}</div>;
  if (!data?.client_by_pk) return null;
  return (
    <Container sx={{ mt: 3, mb: 2 }}>
      <Paper sx={{ p: 2 }}>
        <ClientForm
          initialValues={data?.client_by_pk}
          onSubmit={async (values) => {
            await updateClient({
              variables: {
                client_id: params.id,
                client_set_input: { ...values.client },
              },
            });
            enqueueSnackbar(
              `${translations[selected_locale].client} ${values.client.first_name} ${values.client.last_name} ${translations[selected_locale].is_succesfully_updated}`,
              {
                variant: "success",
              }
            );
          }}
          onDelete={async () => {
            await deleteClient({ variables: { id: params.id } });
            enqueueSnackbar(
              `${translations[selected_locale].client} ${data.client_by_pk?.first_name} ${data.client_by_pk?.last_name} ${translations[selected_locale].is_succesfully_deleted}`,
              {
                variant: "success",
              }
            );
            navigate(-1);
          }}
        />
      </Paper>
    </Container>
  );
};

export default ClientIdPage;
